<template>
  <BasicCrud
    ref="crud"
    :fields="fields"
    :title="$t('Users')"
    :new-title="$t('Invite user')"
    :edit-title="$t('Edit user')"
    :new-button-title="$t('Invite')"
    :new-button-text="$t('Invite')"
    custom-save-event="save"
    path="user"
    :customTableTemplates="customTableTemplates"
    :boolColumns="boolColumns"
    :disabledEditFields="disabledEditFields"
    newFocusField="email"
    editFocusField="roles"
    :defaultNewValues="defaultNewValues"
    @save="invite"
  ></BasicCrud>
</template>

<script>

import Vue from 'vue'
import { mapActions, mapState } from 'vuex'
import BasicCrud from '@/components/BasicCrud'
import api from '@/services/api'
import AppActions from '@/store/app/actions-types'

export default {
  name: "User",

  components: {
    BasicCrud
  },

  computed: mapState({
    fields: state => state.user.fields
  }),

  data() {
    return {
      customTableTemplates: [
        {
          name: 'item.roles',
          callback: (item) => item.roles.map(role => role = role.replace('ROLE_', '').toLowerCase()).join(', ')
        }
      ],

      boolColumns: ['item.active'],

      disabledEditFields: ['email'],

      defaultNewValues: [{field: 'active', value: true}]
    }
  },

  mounted() {
    api.find('unit', {limit: 2000, order: 'name'})
      .then((response) => {
        let field = this.fields.find(field => field.name == 'admins')

        Vue.set(field, 'items', response.data.results)
      })
  },

  methods: {
    ...mapActions('app', [
      AppActions.OPEN_APP_SUCCESS_MESSAGE
    ]),

    invite(data) {
      api.create('user/invite', data)
        .then(() => {
          this[AppActions.OPEN_APP_SUCCESS_MESSAGE](this.$t('Success on invite user'))
          this.$refs.crud.closeModal()
          this.$refs.crud.reloadData()
        })
        .catch(() => this.$refs.crud.setSubmitting(false))
    }
  }
};
</script>
